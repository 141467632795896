<template>
  <NewOtherIncomeUser
    :OI="OI"
    :countries="countries"
    :incomeTypes="incomeTypes"
    @save="save"
  />
</template>

<script>
  import NewOtherIncomeUser from "./OtherIncomeEditingSection/OtherIncomeEditingSection";
  import {OtherIncome} from "../../models/OtherIncome";
  import {otherIncomeMixin} from "../../../../../mixins/otherIncomeMixins/otherIncomeMixin";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "OtherIncomeEditing",
    components: {
      NewOtherIncomeUser,
    },

    mixins: [otherIncomeMixin],

    created() {
      this.$store.dispatch('getOtherIncomeCreate').then(response => {

        let respData = this.getRespData(response)
        this.countries = respData.countries
        this.incomeTypes = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE.value]

        this.$store.dispatch('getOtherIncome', this.$route.params.id).then(response => {
          this.item = this.getRespData(response)
          this.OI.setItem(this.item, this.countries)

          let files = [
            ...this.item?.file_entities,
          ]

          this.downloadFileFromArray(files).then((documents) => {
            let filesObj = this.OI.data.Files
            filesObj.setDownloadFiles(documents)
          })

          // if(this.item.file && this.item.file.length > 0 && this.item.file[0].extension !== 'pdf'){
          //   this.getFileFromServer(this._.find(this.item.file, {type: 'small_image'}))
          // }
          //
          // if(this.item.file && this.item.file.length > 0 && this.item.file[0].extension === 'pdf'){
          //   this.getFileFromServer(this._.find(this.item.file, {extension: 'pdf'}))
          // }
        }).catch(error => this.createErrorLog(error))
      })
    },

    data() {
      return {
        OI: new OtherIncome(),
        countries: [],
        incomeTypes: [],
      }
    },

    methods: {
      async downloadFileFromArray(array) {
        let downloadedFilesArray = []
        for (const item of array) {
          if (item?.type === 'small_image') {
            await this.getFileFromServerReturnObject(item, 'image').then(document => {
              downloadedFilesArray.push(document)
            })
          }

          if (item?.extension === 'pdf') {
            await this.getFileFromServerReturnObject(item, 'pdf').then(document => {
              downloadedFilesArray.push(document)
            })
          }
        }
        return downloadedFilesArray
      },
    }

  }
</script>

<style scoped>

</style>
